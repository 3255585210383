import { Grain, Image, VpnKey, AccountBalanceWallet } from "@material-ui/icons";

import Login from "views/auth/Login.js";
import NftsList from "views/admin/nfts";

var adminRoutes = [
    {
        path: "/dashboard",
        name: "NFTs",
        icon: Image,
        iconColor: "Primary",
        component: NftsList,
        layout: "/app",
        visibility: true,
    },
    {
        divider: true,
        layout: "/app",
        visibility: true,
    },
];

var authRoutes = [
    {
        path: "/login",
        name: "Login",
        icon: VpnKey,
        iconColor: "Info",
        component: Login,
        layout: "/auth",
    },
];
export { adminRoutes, authRoutes };
