import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Container, Box, Grid, Card } from "@material-ui/core";
// core components
import CustomHeader from "components/Headers/CustomHeader";

import componentStyles from "assets/theme/views/admin/tables.js";
import CustomTable from "components/CustomTable/CustomTable";
import CustomTextButton from "components/CustomButton/CustomTextButton";
import { listAllNfts, resetNftsData } from "store/actions";
import { handleSingleNft } from "./CreateSingleNft";

const useStyles = makeStyles(componentStyles);

function NftsList() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { nftsData } = useSelector(({ nfts: { list } }) => ({
        nftsData: list,
    }));

    useEffect(() => {
        dispatch(resetNftsData());
        dispatch(listAllNfts());
    }, []);

    return (
        <>
            <CustomHeader />
            <Container maxWidth={false} component={Box} marginTop="-6rem" classes={{ root: classes.containerRoot }}>
                <Grid container justify="flex-start" spacing={3}>
                    <Grid item style={{ width: "100%" }}>
                        <Card style={{ backgroundColor: "grey" }}></Card>
                    </Grid>
                </Grid>
                <br />
                <CustomTable
                    title="NFTs"
                    columns={[
                        {
                            title: "Actions",
                            render: (rowData) => (
                                <CustomTextButton
                                    color="primary"
                                    variant="outlined"
                                    onClick={() => handleSingleNft(rowData)}
                                    title="Create NFT"
                                    size="small"
                                />
                            ),
                        },
                        {
                            title: "Name",
                            field: "name",
                        },
                        { title: "Email", field: "email" },
                        // { title: "QR code", render: (rowData) => <img src={rowData?.qr_url} /> },
                        { title: "Status", field: "nft_status" },
                    ]}
                    data={nftsData}
                />
            </Container>
        </>
    );
}

export default NftsList;
