import { toast } from "react-toastify";
import { TOGGLE_LOADING, LIST_ALL_NFTS, RESET_NFT_LIST_DATA } from "../types";
import { post } from "services";
import { FETCH_ALL_NFTS, UPDATE_NFT_DATA } from "graphql";

export const listAllNfts = () => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: FETCH_ALL_NFTS,
        });

        if (apiResponse.data.customStatus) {
            let {
                list_all_nfts: { data },
            } = apiResponse.data.data;

            dispatch({
                type: LIST_ALL_NFTS,
                payload: data,
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetNftsData = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_NFT_LIST_DATA });
    };
};

export const updateNftData = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: UPDATE_NFT_DATA,
            variables: requestData,
        });
        dispatch({ type: TOGGLE_LOADING, payload: false });

        if (apiResponse.data.customStatus) {
            toast.success("NFT Generated");
            dispatch(resetNftsData());
            dispatch(listAllNfts());
        }
    };
};
