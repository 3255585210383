import { Connection, actions } from "@metaplex/js";
import { toast } from "react-toastify";
import { store } from "store/store";
import { apiCall, updateNftData } from "store/actions";
import { FILE_UPLOAD } from "graphql";
import { SOL_LEDGER_ACCOUNT, SOLANA_CLUSTER, getMetadata } from "helpers";
import { toggleLoading } from "store/actions";

export const handleSingleNft = async (value) => {
    store.dispatch(toggleLoading(true));

    try {
        let metadata = getMetadata({
            name: value?.name ?? "",
            description: `${value?.name ?? ""} profile`,
            royaltyPercentage: 1 * 100,
            imageUrl: value.qr_url,
            imageType: `image/png`,
            category: "image",
            creators: [{ address: SOL_LEDGER_ACCOUNT, share: 100 }],
        });

        let objJsonStr = JSON.stringify(metadata);
        let objJsonB64 = Buffer.from(objJsonStr).toString("base64");

        let metadataUpload = await apiCall(FILE_UPLOAD, { file: objJsonB64, fileType: "json" });
        let metadataUrl = metadataUpload.data?.upload_file?.url;

        const connection = new Connection(SOLANA_CLUSTER);
        const resp = await window.solana.connect();

        const NftMint = await actions.mintNFT({
            connection: connection,
            wallet: window.solana,
            uri: metadataUrl,
            maxSupply: 1,
        });

        let nftId = NftMint.mint.toString();

        store.dispatch(updateNftData({ socAccId: value._id, nftAddress: nftId }));
    } catch (err) {
        store.dispatch(toggleLoading(false));
        toast.error(err.message);
    }
};
