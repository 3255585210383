export const ADMIN_LOGIN = `query ($email: String!, $password: String!) {
    admin_login(email: $email, password: $password) {
      message
      token
    }
  }
  `;

export const FILE_UPLOAD = `mutation ($file: String!, $fileType: String) {
    upload_file(file: $file, file_type: $fileType) {
      message
      url
    }
  }`;
